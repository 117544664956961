import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import Projects from '../components/projects'
import SEO from '../components/seo'
import Footer from '../components/footer'

const postProjects = ({ data }) => {
	return (
		<React.Fragment>
			<Layout>
				<SEO
					title="Projects"
					keywords={[`portfolio`, `ux`, `ui`, `design-system`, `prototyping`]}
				/>
				<Projects />
			</Layout>
			<Footer
				message={data.wordpressPage.acf.footer.message}
				link={data.wordpressPage.acf.footer.link}
			/>
		</React.Fragment>
	)
}

export default postProjects

export const projectQuery = graphql`
	query {
		wordpressPage(
			guid: { eq: "https://examplebackend.timacumdev.com/?page_id=240" }
		) {
			acf {
				footer {
					message
					link {
						title
						url
						target
					}
				}
			}
		}
	}
`
